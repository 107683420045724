import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { FaInstagram } from "react-icons/fa";
import { MdMail, MdPhone } from "react-icons/md";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import { SafeHtmlParser } from "../components/safe-html-parser";

const ContactMePage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "contact-me" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        contactPageFieldGroups {
          contactDetails {
            email {
              target
              title
              url
            }
            instagramHandle {
              target
              title
              url
            }
            phone {
              target
              title
              url
            }
          }
          contactHeroSection {
            formButtonLabel
            heading
          }
          contactHowToFindSection {
            heading
            address
            addressLink
          }
          location
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, contactPageFieldGroups },
  } = data;

  const { contactDetails, contactHeroSection, contactHowToFindSection, location } =
    contactPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Contact Me",
        item: {
          url: `${siteUrl}/contact-me`,
          id: `${siteUrl}/contact-me`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/contact-me`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          <section className="py-5 py-lg-7">
            <Container>
              <Row>
                <Col className="text-center">
                  <h1 className="display-3 text-grey-med">
                    <SafeHtmlParser htmlContent={contactHeroSection?.heading} />
                  </h1>
                </Col>
              </Row>
              <Row
                id="form"
                className="justify-content-center pt-5 pt-lg-7 g-5 align-items-center"
              >
                <Col lg={4} xl={5}>
                  <h2 className="text-grey-med">
                    {contactHowToFindSection?.heading}
                  </h2>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={contactHowToFindSection?.addressLink}
                    className="secondary-link-primary"
                  >
                    {contactHowToFindSection?.address && (
                      <SafeHtmlParser
                        htmlContent={contactHowToFindSection?.address}
                      />
                    )}
                  </a>
                  {contactDetails.phone && (
                    <div className="d-flex align-items-center pt-4 pb-3">
                      <MdPhone className="text-primary me-2 fs-4" />
                      <a
                        href={contactDetails.phone?.url}
                        className="secondary-link-primary pb-0 mb-0"
                      >
                        {contactDetails.phone?.title}
                      </a>
                    </div>
                  )}

                  {contactDetails.email && (
                    <div className="d-flex align-items-center pb-3">
                      <MdMail className="text-primary me-2 fs-4" />
                      <a
                        href={contactDetails.email?.url}
                        className="secondary-link-primary pb-0 mb-0"
                      >
                        {contactDetails.email?.title}
                      </a>
                    </div>
                  )}
                  {contactDetails.instagramHandle && (
                    <div className="d-flex align-items-center">
                      <FaInstagram className="text-primary me-2 fs-4" />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={contactDetails.instagramHandle?.url}
                        className="secondary-link-primary pb-0 mb-0"
                      >
                        {contactDetails.instagramHandle?.title}
                      </a>
                    </div>
                  )}
                </Col>
                <Col lg={8} xl={7}>
                  <ContactForm btnText={contactHeroSection?.formButtonLabel} />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="bg-secondary">
            <iframe
              className="position-relative "
              src={location}
              width="100%"
              height="100%"
              style="border:0; min-height: 60vh;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </section>
        </Layout>
      </div>
    </>
  );
};

export default ContactMePage;
